/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { Success } from 'qonto/react/payment-links/components/flows/creation/success';

interface PaymentLinksCreationSuccessSignature {
  Args: FlowStepArgs<{
    checkoutUrl: string;
  }>;
}

export default class PaymentLinksCreationSuccess extends Component<PaymentLinksCreationSuccessSignature> {
  success = Success;

  @action
  handleAbort() {
    this.args.abort();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Creation::Success': typeof PaymentLinksCreationSuccess;
  }
}
