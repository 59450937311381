export default {
  "journey": "jP",
  "content": "jl",
  "distance": "jX",
  "google-link-container": "jC",
  "link-icon": "jk",
  "continue-btn": "jJ",
  "origin": "js",
  "input-width": "jF"
};
