export default {
  "container": "oB",
  "numbering": "oE",
  "sideview": "od",
  "form": "oI",
  "numbering-fields": "ot",
  "row": "oM",
  "label-tooltip": "oP",
  "tooltip-icon": "ol",
  "legend": "oX",
  "next-invoice-number": "oC",
  "pdf-preview": "ok",
  "de-layout": "oJ",
  "de-pdf-preview": "os"
};
