/* import __COLOCATED_TEMPLATE__ from './amount.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Amount } from '@repo/domain-kit/financing';

interface AmountSignature {
  // The arguments accepted by the component
  Args: {
    context: {
      maxAmount: {
        currency: string;
        value: string;
      };
      minAmount: {
        currency: string;
        value: string;
      };
      amount: {
        currency: string;
        value: string;
      };
      origin: string;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class AmountComponent extends Component<AmountSignature> {
  amountStep = Amount;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked errorMessage;

  constructor() {
    // @ts-ignore
    super(...arguments);
    this.segment.track('pay-later-topupflow-started', {
      origin: this.args.context.origin,
    });
  }

  get subtitle() {
    return this.intl.t('financing.pay-later.topup.amount.subtitle', {
      min: this.intl.formatNumber(this.minAmount, {
        minimumFractionDigits: 0,
        currency: this.args.context.minAmount.currency,
        style: 'currency',
      }),
      max: this.intl.formatNumber(this.maxAmount, {
        minimumFractionDigits: 0,
        currency: this.args.context.maxAmount.currency,
        style: 'currency',
      }),
    });
  }

  get maxAmount(): number {
    return parseFloat(this.args.context.maxAmount.value);
  }

  get minAmount(): number {
    return parseFloat(this.args.context.minAmount.value);
  }

  validateAmount(amount: string): boolean {
    let parsedAmount = parseFloat(amount);

    if (!amount || parsedAmount < this.minAmount) {
      this.errorMessage = this.intl.t('financing.pay-later.topup.amount.input.errors.min', {
        min: this.intl.formatNumber(this.minAmount, {
          minimumFractionDigits: 0,
          currency: this.args.context.minAmount.currency,
          style: 'currency',
        }),
      });
      return false;
    } else if (parsedAmount > this.maxAmount) {
      this.errorMessage = this.intl.t('financing.pay-later.topup.amount.input.errors.max', {
        max: this.intl.formatNumber(this.maxAmount, {
          minimumFractionDigits: 0,
          currency: this.args.context.maxAmount.currency,
          style: 'currency',
        }),
      });
      return false;
    }
    return true;
  }

  @action
  handleChange() {
    this.errorMessage = null;
  }

  @action
  handleSubmit(amount: string) {
    if (this.validateAmount(amount)) {
      this.args.context.amount.value = amount;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Topup::Amount': typeof AmountComponent;
  }
}
