export default {
  "container": "oa",
  "summary": "oH",
  "sideview": "oO",
  "steps-container": "om",
  "invoice-upload-container": "op",
  "pdf-preview": "ox",
  "uploader": "ow",
  "template-text": "oN",
  "file-dropzone": "ov",
  "overlay": "oo",
  "footer": "on",
  "upload-prompt": "oi",
  "step-selector": "oA",
  "disclaimer": "oY"
};
