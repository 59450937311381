export default {
  "container": "uJ",
  "has-background": "us",
  "wrapper": "uF",
  "error-wrapper": "ug",
  "bottom-btns": "uD",
  "bottom-wrapper": "uV",
  "tab-nav": "uL",
  "tab-nav-small": "uT",
  "btn-clear": "uz",
  "error-illustration": "ua",
  "error-title": "uH title-1",
  "disclaimer-wrapper": "uO",
  "disclaimer": "um",
  "error-description": "up body-2",
  "table-wrapper": "ux",
  "title": "uw title-1",
  "lineup-toggle": "uN",
  "description": "uv body-2",
  "loading-wrapper": "uo",
  "card": "un",
  "plan": "ui",
  "placeholder-header": "uA",
  "disclaimer-info": "uY",
  "spinner": "uh"
};
