/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import Component from '@glimmer/component';

import { SubscriptionCloseSuccess } from 'qonto/react/components/flows/subscription/close/success';

interface SubscriptionCloseStepsReasonSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsReasonSuccessComponent extends Component<SubscriptionCloseStepsReasonSuccessSignature> {
  subscriptionCloseSuccess = SubscriptionCloseSuccess;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Success': typeof SubscriptionCloseStepsReasonSuccessComponent;
  }
}
