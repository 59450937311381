export default {
  "wrapper": "cqW input-field mb-4",
  "error": "cqu",
  "inactive": "cZS",
  "hidden-value": "cZc",
  "percentage-sign": "cZq",
  "black": "cZZ",
  "input-field": "cZR",
  "disabled": "cZQ"
};
