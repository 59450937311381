export default {
  "full-height": "ccH",
  "centered": "ccO",
  "ocr-animation": "ccm",
  "separator": "ccp",
  "vat-accounting": "ccx",
  "label-title": "ccw",
  "label-select-override": "ccN",
  "amount-and-reference-wrapper": "ccv",
  "amount-wrapper": "cco",
  "payable-amount-tooltip": "ccn",
  "sticky-panel": "cci",
  "accordion": "ccA",
  "chips-wrapper": "ccY",
  "chip": "cch"
};
