export default {
  "wrapper": "iP",
  "wrapper-new": "il",
  "icon-list": "iX",
  "list-item": "iC",
  "box-wrapper": "ik",
  "panel": "iJ",
  "panel-top": "is",
  "list": "iF",
  "container": "ig",
  "column": "iD",
  "icon": "iV",
  "column-right": "iL"
};
