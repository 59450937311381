/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import type IntlService from 'ember-intl/services/intl';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import type PaymentLinkModel from 'qonto/models/payment-link';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { Settings } from 'qonto/react/payment-links/components/flows/creation/settings';

interface PaymentLinksCreationSettingsSignature {
  Args: FlowStepArgs<{
    selectedItems: BasketItem[];
    selectedMethods: PaymentLinkMethodModel['method'][];
    organizationName: string;
    settings: PaymentLinkModel['settings'];
    checkoutUrl: string;
  }>;
}

export default class PaymentLinksCreationSettings extends Component<PaymentLinksCreationSettingsSignature> {
  settings = Settings;

  @service intl!: IntlService;

  get breadcrumb() {
    return [
      {
        title: this.intl.t('payment-link.create.step-1.navigation'),
        active: false,
        onClick: () => {
          this.args.backToStep('products-and-services');
        },
      },
      {
        title: this.intl.t('payment-link.create.step-2.navigation'),
        active: false,
        onClick: () => {
          this.args.transitionToPrevious();
        },
      },
      {
        title: this.intl.t('payment-link.create.step-3.navigation'),
        active: true,
      },
    ];
  }

  @action
  handleAbort() {
    this.args.abort();
  }

  @action
  handleContinue(checkoutUrl: string) {
    this.args.context.checkoutUrl = checkoutUrl;
    this.args.transitionToNext();
  }

  @action
  handleSettingsChange(settings: PaymentLinkModel['settings']) {
    this.args.context.settings = settings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Creation::Settings': typeof PaymentLinksCreationSettings;
  }
}
