export default {
  "wrapper": "Wb",
  "confirm": "Wy",
  "plan-info": "WU",
  "plan-info-left": "Wj",
  "info-title": "WW body-1",
  "initial-trial-content": "Wu",
  "extra-fees-item": "uS body-2",
  "extra-fees-container": "uc",
  "bottom-wrapper": "uq",
  "total-price-container": "uZ",
  "extra-fees-element": "uR body-1",
  "border": "uQ",
  "subtitle": "ue body-2",
  "group-item": "uB",
  "amount": "uE",
  "subscription-billing-summary": "ud",
  "summary": "uI",
  "summary-list": "ut",
  "disclaimer": "uM",
  "disclaimer-revamp": "uP",
  "error": "ul",
  "error-link": "uX",
  "warning-wrapper": "uC",
  "warning-label": "uk body-1"
};
