export default {
  "step-container": "KE",
  "content": "Kd",
  "detail": "KI",
  "secondary": "Kt",
  "reference": "KM",
  "fees-label": "KP",
  "fees-tooltip": "Kl",
  "separator": "KX",
  "mt-32": "KC"
};
