export default {
  "container": "Av",
  "container-metal": "Ao",
  "content": "An",
  "button": "Ai",
  "embossed-selector": "AA",
  "carousel-container": "AY",
  "carousel": "Ah",
  "asset": "Af",
  "poster": "AK",
  "video-embossed": "AG",
  "video": "Ar",
  "active": "Ab",
  "embossed": "Ay",
  "safari": "AU",
  "poster-open": "Aj"
};
