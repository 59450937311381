/* import __COLOCATED_TEMPLATE__ from './products-and-services.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import type IntlService from 'ember-intl/services/intl';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import type { Amount } from 'qonto/react/models/amount';
import { ProductsAndServices } from 'qonto/react/payment-links/components/flows/creation/products-and-services';
import { validateBasketItemList } from 'qonto/react/payment-links/utils/validate-basket-items';

interface PaymentLinksCreationProductsAndServicesSignature {
  Args: FlowStepArgs<{
    selectedItems: BasketItem[];
    selectedMethods: PaymentLinkMethodModel['method'][];
    expandedItems: string[];
    totalAmount: Amount<string>;
    organizationName: string;
    vatRatesCategories: number[];
  }>;
}

export default class PaymentLinksCreationProductsAndServices extends Component<PaymentLinksCreationProductsAndServicesSignature> {
  productsAndServices = ProductsAndServices;

  @service intl!: IntlService;

  get breadcrumb() {
    const canContinue =
      this.args.context.selectedItems.length > 0 &&
      validateBasketItemList(this.args.context.selectedItems).length === 0;

    return [
      { title: this.intl.t('payment-link.create.step-1.navigation'), active: true },
      {
        title: this.intl.t('payment-link.create.step-2.navigation'),
        active: false,
        disabled: !canContinue,
        onClick: () => {
          if (canContinue) {
            this.args.transitionToNext();
          }
        },
      },
      {
        title: this.intl.t('payment-link.create.step-3.navigation'),
        active: false,
        disabled: true,
      },
    ];
  }

  @action
  handleAbort() {
    this.args.abort();
  }

  @action
  handleSelectItem(product: BasketItem) {
    this.args.context.selectedItems = [...this.args.context.selectedItems, product];
  }

  @action
  handleRemoveItem(product: BasketItem) {
    this.args.context.selectedItems = this.args.context.selectedItems.filter(
      p => p.id !== product.id
    );
  }

  @action
  handleContinue() {
    this.args.transitionToNext();
  }

  @action
  handleUpdateTotalAmount(amount: Amount<string>) {
    this.args.context.totalAmount = amount;
  }

  @action
  handleExpandItem(productId: string) {
    if (this.args.context.expandedItems.includes(productId)) {
      this.args.context.expandedItems = this.args.context.expandedItems.filter(
        id => id !== productId
      );
    } else {
      this.args.context.expandedItems = [...this.args.context.expandedItems, productId];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Creation::ProductsAndServices': typeof PaymentLinksCreationProductsAndServices;
  }
}
