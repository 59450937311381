export default {
  "container": "oU",
  "customize-field": "oj",
  "content": "oW",
  "sideview": "ou",
  "form": "nS",
  "footer": "nc",
  "layout": "nq",
  "pdf-preview": "nZ",
  "german-preview": "nR"
};
