export default {
  "container": "WZ",
  "form-core": "WR",
  "label": "WQ",
  "request-description": "We",
  "radio-option": "WB",
  "radio-button": "WE",
  "inline-inputs": "Wd",
  "placeholder-label": "WI",
  "placeholder-input": "Wt",
  "placeholder-checkbox": "WM"
};
