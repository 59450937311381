export default {
  "label-wrapper": "hm body-1",
  "container": "hp",
  "transaction-info": "hx",
  "secondary": "hw",
  "amount-container": "hN",
  "amount": "hv",
  "disabled": "ho",
  "checkbox": "hn",
  "disclaimer": "hi"
};
