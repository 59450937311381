export default {
  "wrapper": "cRS",
  "close-button": "cRc",
  "content-wrapper": "cRq",
  "main": "cRZ",
  "main-content": "cRR",
  "disclaimer": "cRQ",
  "description": "cRe",
  "mt-26": "cRB",
  "mt-62": "cRE",
  "user-prompt": "cRd",
  "user-prompt-inappropriate-warning": "cRI",
  "logo-preview": "cRt",
  "static-loader": "cRM",
  "loading-state-illustration": "cRP",
  "sticky-panel": "cRl"
};
