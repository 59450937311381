export default {
  "summary-description": "cqS",
  "detail": "cqc",
  "detail-value": "cqq",
  "account-icon": "cqZ",
  "color-secondary": "cqR",
  "beneficiary-info": "cqQ",
  "currency": "cqe",
  "beneficiary-name": "cqB",
  "separator": "cqE",
  "instant-subtitle": "cqd",
  "instant-wrapper": "cqI",
  "instant-header": "cqt",
  "instant-toggle": "cqM",
  "instant--disabled": "cqP",
  "limit-exceeded-disclaimer": "cql",
  "sticky-panel": "cqX",
  "total-interest": "cqC",
  "tooltip-icon": "cqk",
  "tooltip": "cqJ",
  "payment-options": "cqs",
  "starting-on-value": "cqF",
  "standard-transfer-tooltip": "cqg"
};
