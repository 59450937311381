/* import __COLOCATED_TEMPLATE__ from './signature-failure.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SignatureFailure } from 'qonto/react/financing/components/flows/pay-later/transfer/pay-later-application/signature-failure';

interface FlowsTransfersPayLaterApplicationSignatureFailureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersPayLaterApplicationSignatureFailureComponent extends Component<FlowsTransfersPayLaterApplicationSignatureFailureSignature> {
  signatureFailure = SignatureFailure;
  @service declare segment: Services['segment'];

  constructor(
    owner: unknown,
    args: FlowsTransfersPayLaterApplicationSignatureFailureSignature['Args']
  ) {
    super(owner, args);
    this.segment.track('pay-later_signed_error_displayed', { origin: 'transfer' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::PayLaterApplication::SignatureFailure': typeof FlowsTransfersPayLaterApplicationSignatureFailureComponent;
  }
}
