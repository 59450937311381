export default {
  "animated": "AX",
  "container": "AC",
  "header": "Ak",
  "footer": "AJ",
  "skip-cta": "As",
  "pricing-caption": "AF",
  "asset-x": "Ag",
  "asset-plus": "AD",
  "asset-one": "AV",
  "show": "AL",
  "selector": "AT",
  "selectorFadeIn": "Az",
  "fadeIn": "Aa",
  "onePlusAssetFadeIn": "AH",
  "assetMoveUp": "AO",
  "xAssetFadein": "Am",
  "feature": "Ap",
  "highlighted": "Ax"
};
