/* import __COLOCATED_TEMPLATE__ from './review-reorder.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { FLAT_ASSETS } from '@repo/cards-kit/constants';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { CARD_DESIGNS, CARD_NAMES } from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';
import { CardReviewPanel } from 'qonto/react/cards/components/card-review-panel';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

// we use the same IT FAQ for all languages (because this flow is dedicated to IT market)
const FAQ_LINK =
  'https://support-it.qonto.com/hc/it/articles/23950004508305-Ho-bisogno-di-aiuto-come-contatto-il-servizio-clienti';

interface FlowsCardsReviewReorderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsReviewReorder extends Component<FlowsCardsReviewReorderSignature> {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  cardReviewPanel = CardReviewPanel;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  get isPlusBlackLimitedEdition() {
    // @ts-expect-error
    return CARD_DESIGNS.PLUS_BLACK_2023 === this.args.context.parentCard.cardDesign;
  }

  get cardDesign() {
    return this.isPlusBlackLimitedEdition
      ? CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023
      : // @ts-expect-error
        this.args.context.parentCard.cardDesign;
  }

  get cardImageUri() {
    // @ts-expect-error
    let { parentCard } = this.args.context;

    // in case of outdated limited design, we fall back to default designs
    let design = this.isPlusBlackLimitedEdition
      ? CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023
      : parentCard.cardDesign;

    // @ts-expect-error
    return FLAT_ASSETS[design];
  }

  get cardFullName() {
    // @ts-expect-error
    let { parentCard } = this.args.context;

    // in case of outdated limited design, we fall back to default designs
    // and thus the card full name
    if (this.isPlusBlackLimitedEdition) {
      // @ts-expect-error
      let name = CARD_NAMES[parentCard.cardLevel];
      return this.intl.t('cards.fullname.physical', { cardName: name });
    }

    return parentCard.fullName;
  }

  get disclaimerText() {
    return this.intl.t('cards.steps.review-reorder.disclaimer.text', {
      // @ts-expect-error
      faq_link: htmlSafe(
        `<a
          href=${FAQ_LINK}
          target="_blank"
          rel="noopener noreferrer"
          data-test-link
        >${this.intl.t('cards.steps.review-reorder.disclaimer.link')}</a>`
      ),
      htmlSafe: true,
    });
  }

  onConfirmTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let { parentCard } = this.args.context;
      // @ts-expect-error
      this.args.context.card = await parentCard.reorder();
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ReviewReorder': typeof FlowsCardsReviewReorder;
  }
}
