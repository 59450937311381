export default {
  "color-option": "cZg",
  "color-dot": "cZD",
  "any": "cZV",
  "red": "cZL",
  "orange": "cZT",
  "yellow": "cZz",
  "green": "cZa",
  "blue": "cZH",
  "purple": "cZO",
  "pink": "cZm",
  "black": "cZp",
  "grey": "cZx",
  "brown": "cZw",
  "color-name": "cZN"
};
