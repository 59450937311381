/* import __COLOCATED_TEMPLATE__ from './plans.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { decamelize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, LottiePlayer, Spinner, ToggleButton } from '@repo/design-system-kit';
import { PricePlanContainerSkeleton, PricePlansContainerBridge } from '@repo/domain-kit/pricing';
import { all, dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { Addons } from 'qonto/constants/addons';
import { PRICE_PLANT_TRAITS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsSubscriptionChangePlansSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsSubscriptionChangePlansComponent extends Component<FlowsSubscriptionChangePlansSignature> {
  lottiePlayer = LottiePlayer;

  spinner = Spinner;

  toggleButton = ToggleButton;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  pricePlansContainerBridge = PricePlansContainerBridge;

  skeleton = PricePlanContainerSkeleton;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare modals: Services['modals'];

  @tracked pricePlans = [];
  @tracked overviewPricePlans = [];
  @tracked selectedPricePlanCode = null;
  @tracked recommendedPricePlan = null;
  @tracked shouldSkipPlansStep = true; // Set true as default to prevent show plan step when skipping to confirm step
  @tracked isRedirectionPending = false;

  fetchPlansPromise = null;

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  constructor(owner: unknown, args: FlowsSubscriptionChangePlansSignature['Args']) {
    super(owner, args);
    next(() => {
      this.initTask.perform().catch(ignoreCancelation);
    });
  }

  get recurrenceToggle() {
    return {
      items: [
        {
          value: this.SUBSCRIPTION_RECURRENCES.MONTHLY,
          label: this.intl.t('subscription.change.plans.monthly'),
        },
        {
          value: this.SUBSCRIPTION_RECURRENCES.ANNUAL,
          label: this.intl.t('subscription.change.plans.annual-discount'),
        },
      ],
      variant: 'default',
    };
  }

  initTask = dropTask(async () => {
    try {
      let {
        plan: selectedPricePlanCode,
        recurrence: selectedPricePlanRecurrence = SUBSCRIPTION_RECURRENCES.ANNUAL,
      } = this.router.currentRoute.queryParams;

      // @ts-expect-error
      this.fetchPlansPromise = () =>
        this.store.query('subscription-product', {
          type: 'core',
          includes: 'product_compatibilities',
          include_decoration: true,
        });

      if (selectedPricePlanCode) {
        this.isRedirectionPending = true;
        try {
          // @ts-expect-error
          this.pricePlans = await this.fetchPlansPromise();
          // @ts-expect-error
          await this.confirmPlanTask.perform(selectedPricePlanCode, selectedPricePlanRecurrence);
        } catch (error) {
          this.errors.handleError(error);
        }
      }
      this.isRedirectionPending = false;
      this.shouldSkipPlansStep = false;
      await this.fetchPricePlansTask.perform();

      if (this.#getIsEligibleForAddonDisclaimer()) {
        await this.fetchActiveArFeaturesTask.perform().catch(ignoreCancelation);
      }
    } catch {
      // ignore errors
    }
  });

  lineups = [
    {
      value: 'solo',
      // @ts-expect-error
      label: this.intl.t('subscription.change.plans.solo.lineup-toggle'),
    },
    {
      value: 'teams',
      // @ts-expect-error
      label: this.intl.t('subscription.change.plans.teams.lineup-toggle'),
    },
  ];

  get currentPricePlanCode() {
    return this.subscriptionManager.currentPricePlan.code;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get overviewPricePlansByLineup() {
    // @ts-expect-error
    return this.overviewPricePlans.filter(({ lineup }) => lineup === this.args.context.lineup);
  }

  get activeTrial() {
    return this.subscriptionManager.currentSubscription?.activeTrial;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get initialTrialEndDate() {
    return this.activeTrial?.end_date;
  }

  get hasLegacyPlanDisclaimer() {
    return this.subscriptionManager.currentPricePlan.disabled;
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      // @ts-expect-error
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get showArAddonDisclaimer() {
    if (!this.#getIsEligibleForAddonDisclaimer()) {
      return false;
    }

    let { invoiceSubscriptionsStats, reminders } =
      this.fetchActiveArFeaturesTask.lastSuccessful?.value || {};

    // @ts-expect-error
    let hasActiveReminders = reminders?.length > 0;
    let hasActiveInvoiceSubscriptions = invoiceSubscriptionsStats?.created.active > 0;

    return hasActiveInvoiceSubscriptions || hasActiveReminders;
  }

  // @ts-expect-error
  #formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }

  // @ts-expect-error
  getTraits(pricePlanCode, recurrence) {
    let traits = [];
    if (
      this.hasInitialTrial ||
      this.subscriptionManager.hasAvailableTrialProduct(pricePlanCode, recurrence)
    ) {
      traits.push({
        name: PRICE_PLANT_TRAITS.free_trial,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.free-trial'),
      });
    }
    if (this.isActivePlan(pricePlanCode, recurrence)) {
      traits.push({
        name: PRICE_PLANT_TRAITS.active,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.active'),
      });
    }

    if (
      // @ts-expect-error
      this.recommendedPricePlan?.recommended_product?.code === pricePlanCode &&
      // @ts-expect-error
      this.recommendedPricePlan?.recommended_recurrence === recurrence
    ) {
      traits.push({
        name: PRICE_PLANT_TRAITS.recommended,
        recurrence,
        title: this.intl.t('subscription.change.plans.badges.recommended'),
      });
    }
    return traits;
  }

  // @ts-expect-error
  ctaText(pricePlanCode, recurrence) {
    let message;

    if (this.isActivePlan(pricePlanCode, recurrence)) {
      if (this.activeTrial && !this.hasInitialTrial) {
        message = this.intl.t('subscription.change.plans.summary.trial-activated');
      } else {
        message = this.intl.t('subscription.change.plans.summary.current-plan');
      }
    } else if (
      this.hasInitialTrial ||
      this.subscriptionManager.hasAvailableTrialProduct(pricePlanCode, recurrence)
    ) {
      message = this.intl.t('upsell.discover.trial.cta');
    } else if (this.isOnDifferentRecurrence(pricePlanCode, recurrence)) {
      message =
        recurrence === this.SUBSCRIPTION_RECURRENCES.ANNUAL
          ? this.intl.t('subscription.change.plans.summary.annual')
          : this.intl.t('subscription.change.plans.summary.monthly');
    } else {
      message = this.intl.t('subscription.change.plans.summary.choose-plan');
    }

    return message;
  }

  // @ts-expect-error
  ctaOptions(pricePlanCode, recurrence) {
    return {
      text: this.ctaText(pricePlanCode, recurrence),
      isDisabled: this.isActivePlan(pricePlanCode, recurrence),
    };
  }

  // @ts-expect-error
  getBillingRecurrence(recurrence, monthlyPrice, annualPrice) {
    return recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
      ? monthlyPrice.billingRecurrence
      : annualPrice.billingRecurrence;
  }

  // @ts-expect-error
  trackPlansCtaClick(code, recurrence, traits) {
    this.segment.track('plans_plan_clicked', {
      current_plan: this.currentPricePlanCode,
      target_plan: code,
      end_date: this.initialTrialEndDate,
      recurrence,
      origin: undefined,
    });
  }

  get mappedPricePlansByLineup() {
    return {
      pricePlans: this.overviewPricePlansByLineup.map(
        ({
          code,
          localName,
          monthlyPrice,
          annualPrice,
          description,
          benefitGroups,
          productCompatibilities,
        }) => {
          let { recurrence: selectedPricePlanRecurrence = this.SUBSCRIPTION_RECURRENCES.ANNUAL } =
            this.router.currentRoute.queryParams;
          // @ts-expect-error
          let recurrence = this.args.context.recurrence || selectedPricePlanRecurrence;
          let traits = this.getTraits(code, recurrence);

          let { isDisabled, text } = this.ctaOptions(code, recurrence);

          let base = {
            title: localName,
            traits,
            description,
            ctaDescription: this.getBillingRecurrence(recurrence, monthlyPrice, annualPrice),
            mainButton: {
              text,
              buttonProps: {
                // eslint-disable-next-line no-useless-computed-key
                ['data-test-overview-card-button']: true,
                variant: 'primary',
                isDisabled,
                isLoading:
                  this.selectedPricePlanTitle === localName && this.confirmPlanTask.isRunning,
                onPress: () => {
                  this.confirmPlanTask.perform(code, recurrence, traits).catch(ignoreCancelation);
                  this.trackPlansCtaClick(code, recurrence, traits);
                },
              },
            },
            // @ts-expect-error
            benefitsGroups: benefitGroups.map(benefitGroup => ({
              title: benefitGroup.title,
              // @ts-expect-error
              benefits: benefitGroup.benefits.map(benefit => ({
                description: benefit.description,
                isIncluded: benefit.isIncluded,
              })),
            })),
            addons: {
              title: this.intl.t('subscription.change.plans.available-add-ons.title'),
              // @ts-expect-error
              addons: productCompatibilities.map(addon => ({
                description: codeToName(this.intl, addon.group_code),
                iconLightPath: addon.icon_light_url,
                iconDarkPath: addon.icon_dark_url,
                isAvailable: addon.is_available,
              })),
            },
          };

          if (recurrence === this.SUBSCRIPTION_RECURRENCES.MONTHLY) {
            return {
              ...base,
              recurrence,
              // @ts-expect-error
              price: this.#formatPrice(parseFloat(monthlyPrice.perMonthAmount.value)),
              // @ts-expect-error
              pricePeriod: monthlyPrice.period,
              descriptionCta: {
                type: 'button',
                // @ts-expect-error
                text: monthlyPrice.annualBenefit,
                onPress: () => {
                  this.selectRecurrence(this.SUBSCRIPTION_RECURRENCES.ANNUAL);
                },
              },
            };
          }
          // recurrence === 'annual'
          return {
            ...base,
            recurrence,
            // @ts-expect-error
            price: this.#formatPrice(parseFloat(annualPrice.perMonthAmount.value)),
            // @ts-expect-error
            pricePeriod: annualPrice.period,
            // @ts-expect-error
            monthlyPrice: this.#formatPrice(parseFloat(monthlyPrice.perMonthAmount.value)),
            // @ts-expect-error
            priceDescription: annualPrice.annualBenefit,
          };
        }
      ),
    };
  }

  @action
  // @ts-expect-error
  selectRecurrence(recurrence) {
    // @ts-expect-error
    this.args.context.recurrence = recurrence;
    this.segment.track('recurrence_toggle_clicked', { recurrence });
  }

  @action
  // @ts-expect-error
  selectLineup(lineup) {
    // @ts-expect-error
    this.args.context.lineup = lineup;
    this.segment.track('plans_toggle_clicked', {
      current_plan: this.currentPricePlanCode,
      lineup,
    });
  }

  @action
  // @ts-expect-error
  isFreeTrialPlan(pricePlanCode, rec, traits = []) {
    let traitsByRecurrence = this.#getTraitByRecurrence(traits, rec);
    return traitsByRecurrence[0]?.name === PRICE_PLANT_TRAITS.free_trial;
  }

  // @ts-expect-error
  #getTraitByRecurrence(traits, recurrence) {
    // @ts-expect-error
    return traits.filter(trait => trait.recurrence === recurrence);
  }

  @action
  // @ts-expect-error
  isActiveTrialPlan(pricePlanCode, recurrence) {
    return this.activeTrial ? this.isActivePlan(pricePlanCode, recurrence) : false;
  }

  // @ts-expect-error
  isSamePlan(pricePlanCode) {
    let { currentPricePlan } = this.subscriptionManager;
    return pricePlanCode === currentPricePlan.code;
  }

  @action
  // @ts-expect-error
  isActivePlan(pricePlanCode, rec) {
    return this.isSamePlan(pricePlanCode) && this.currentPricePlanRecurrence === rec;
  }

  @action
  // @ts-expect-error
  isOnDifferentRecurrence(pricePlanCode, rec) {
    return (
      this.isSamePlan(pricePlanCode) &&
      this.subscriptionManager.currentSubscription?.recurrence !== rec
    );
  }

  @action
  onDiscoverAddonClick() {
    // @ts-expect-error
    this.args.pushFlow('addon-change', 'addons');
  }

  // @ts-expect-error
  getRecommendedPricePlan(pricePlans) {
    // @ts-expect-error
    let pricePlan = pricePlans.find(pricePlan => {
      // @ts-expect-error
      return pricePlan.traits.find(item => item.name === PRICE_PLANT_TRAITS.recommended);
    });
    return pricePlan;
  }

  fetchPricePlansTask = dropTask(async () => {
    // @ts-expect-error
    this.args.context.lineup = 'solo';

    // @ts-expect-error
    let { upsellTrigger } = this.args.context;

    let recommendationPromise = null;
    // call upgrade_recommendation endpoint for modular pricing
    if (upsellTrigger) {
      recommendationPromise = this.upsellManager.getUpgradeRecommendation(upsellTrigger);
    }

    let [recommendedPricePlan, pricePlans] = await all([
      recommendationPromise,
      // @ts-expect-error
      this.fetchPlansPromise(),
    ]);

    pricePlans = pricePlans?.length
      ? pricePlans
      : await this.store.query('subscription-product', {
          type: 'core',
          includes: 'product_compatibilities',
          include_decoration: true,
        });

    this.recommendedPricePlan = recommendedPricePlan;

    // @ts-expect-error
    this.setActiveLineup(this.recommendedPricePlan?.recommended_product);

    // @ts-expect-error
    this.setRecurrence(this.recommendedPricePlan?.recommended_recurrence);
    this.overviewPricePlans = pricePlans;
    this.pricePlans = pricePlans;
  });

  fetchActiveArFeaturesTask = dropTask(async () => {
    let invoiceSubscriptionsStats = null;
    let reminders = null;

    try {
      // @ts-expect-error
      invoiceSubscriptionsStats = await this.store.adapterFor('invoice-subscription').getStats();
      reminders = await this.store.findAll('reminders-configuration');
    } catch {
      // ignore errors
    }

    return { invoiceSubscriptionsStats, reminders };
  });

  // @ts-expect-error
  setActiveLineup(recommendedPricePlan) {
    let { lineup } = this.router.currentRoute.queryParams;
    if (lineup) {
      // @ts-expect-error
      this.args.context.lineup = lineup;
    } else if (recommendedPricePlan) {
      // @ts-expect-error
      this.args.context.lineup = recommendedPricePlan.lineup;
    } else {
      let { lineup } = this.subscriptionManager.currentPricePlan;
      // @ts-expect-error
      this.args.context.lineup = lineup;
    }
  }
  // @ts-expect-error
  setRecurrence(recommendedRecurrence) {
    if (recommendedRecurrence) {
      // @ts-expect-error
      this.args.context.recurrence = recommendedRecurrence;
    }
  }

  get selectedPricePlanTitle() {
    if (this.selectedPricePlanCode) {
      // @ts-expect-error
      return this.pricePlans.find(({ code }) => this.selectedPricePlanCode === code)?.localName;
    }
  }

  confirmPlanTask = dropTask(async (code, recurrence, traits) => {
    this.selectedPricePlanCode = code;

    // @ts-expect-error
    let pricePlan = this.pricePlans.find(item => item.code === code);

    let subscription;
    subscription = this.store.createRecord('organization-subscription', {
      organization: this.organizationManager.organization,
      product: pricePlan,
      recurrence,
    });

    // @ts-expect-error
    set(this.args.context, 'subscription', subscription);
    set(
      // @ts-expect-error
      this.args.context,
      'isFreeTrial',
      this.isFreeTrialPlan(code, recurrence, traits) && !this.hasInitialTrial
    );

    let isActiveTrial;
    let { previous_product_id, previous_recurrence } = this.activeTrial || {};
    isActiveTrial =
      // @ts-expect-error
      pricePlan.get('id') === previous_product_id && previous_recurrence === recurrence;

    // @ts-expect-error
    set(this.args.context, 'isActiveTrial', isActiveTrial);
    // @ts-expect-error
    set(this.args.context, 'currentPricePlanCode', this.currentPricePlanCode);

    try {
      let { warnings, total_estimate, extra, target_subscriptions } = await subscription.estimate();
      this._handleNextStep({
        isItalyError: false,
        warnings,
        targetSubscriptions: target_subscriptions,
        extraPrice: extra,
        estimatedPrice: total_estimate,
        subscription,
        hasInsufficientFunds: false,
        errors: [],
        isBlockedForItaly: false,
      });
      // @ts-expect-error
    } catch ({ payload, status }) {
      if (status === 422) {
        let { errors, warnings, total_estimate, target_subscriptions, extra } = payload;
        // @ts-expect-error
        let warningsWithoutNoRefund = warnings.filter(warning => warning.code !== 'no_refund');
        // @ts-expect-error
        let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
        let blockerErrors = errors?.filter(
          // @ts-expect-error
          it => it.code !== 'balance_insufficient_funds' && it.code !== 'boi_change_disallowed'
        );
        let isBlockedForItaly = // @ts-expect-error
          errors?.some(it => it.code === 'boi_change_disallowed');

        if ((!warningsWithoutNoRefund || !warningsWithoutNoRefund.length) && blockerErrors.length) {
          this.modals.open('subscription/blockers-modal', {
            isFullScreenModal: true,
            errors,
            subscription,
            currentPricePlanCode: this.currentPricePlanCode,
            // @ts-expect-error
            transitionToFlow: this.args.transitionToFlow,
          });
        } else {
          this._handleNextStep({
            isItalyError: false,
            warnings,
            targetSubscriptions: target_subscriptions,
            extraPrice: extra,
            estimatedPrice: total_estimate,
            hasInsufficientFunds,
            errors: blockerErrors,
            subscription,
            isBlockedForItaly,
          });
        }
      } else {
        let errorMessage = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(errorMessage);
      }
    }
  });

  #getIsEligibleForAddonDisclaimer() {
    let hasArAddon = this.subscriptionManager.hasAddon(Addons.AccountsReceivable);
    let isLegacyPlan = this.subscriptionManager.currentPricePlan.disabled;
    let hasArInFeaturesInPricePlan =
      this.abilities.can('write reminders-configuration') ||
      this.abilities.can('create invoice-subscription');

    return !hasArAddon && isLegacyPlan && hasArInFeaturesInPricePlan;
  }

  // @ts-expect-error
  _handleNextStep(args) {
    // @ts-expect-error
    setProperties(this.args.context, args);
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::Plans': typeof FlowsSubscriptionChangePlansComponent;
  }
}
