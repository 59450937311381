export default {
  "mandate-new-no-mandate": "rq",
  "left-side": "rZ",
  "left-side__wrapper": "rR",
  "left-side__wrapper-title": "rQ",
  "left-side__wrapper-details": "re",
  "left-side__wrapper-details-item": "rB",
  "right-side": "rE",
  "multi-accounts": "rd",
  "right-side__wrapper": "rI",
  "right-side__wrapper-name": "rt",
  "right-side__wrapper-address": "rM",
  "right-side__wrapper-iban-dashed": "rP",
  "right-side__wrapper-address-label": "rl",
  "right-side__wrapper-iban-label": "rX",
  "right-side__wrapper-bic-label": "rC",
  "right-side__wrapper-address-dashed": "rk",
  "right-side__wrapper-bic-dashed": "rJ",
  "dropdown-icon": "rs",
  "dropdown": "rF",
  "multi-accounts-illustration": "rg"
};
