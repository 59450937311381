export default {
  "header": "Nx",
  "header-top": "Nw",
  "header-inner": "NN",
  "disclaimer": "Nv mb-16",
  "header-main-wrapper": "No",
  "header-main": "Nn",
  "logo": "Ni mr-16",
  "header-right": "NA",
  "partner-description": "NY body-2"
};
