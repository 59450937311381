export default {
  "step-container": "yU",
  "flex-table": "yj",
  "flex-row": "yW",
  "flex-cell": "yu",
  "with-border": "US",
  "with-border-top": "Uc",
  "secondary": "Uq",
  "flex-info": "UZ",
  "amount": "UR",
  "legal-name": "UQ"
};
