export default {
  "two-column-layout": "WP",
  "points-list": "Wl",
  "point-title": "WX",
  "point-content": "WC",
  "old-container": "Wk",
  "columns": "WJ",
  "left-column": "Ws",
  "right-column": "WF",
  "video-wrapper": "Wg",
  "selling-points-wrapper": "WD",
  "actions": "WV"
};
