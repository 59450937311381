export default {
  "content": "cSM",
  "error-message": "cSP",
  "wrapper": "cSl",
  "banner": "cSX",
  "banner-content": "cSC",
  "banner-text": "cSk",
  "banner-image": "cSJ",
  "banner-title": "cSs",
  "banner-description": "cSF body-2",
  "link": "cSg",
  "plans-comparison": "cSD",
  "plan-box": "cSV",
  "arrow": "cSL",
  "arrow-icon": "cST",
  "link-downgrade": "cSz",
  "stroke-text": "cSa"
};
