export default {
  "voucher-wrapper": "cSi",
  "left-side": "cSA",
  "left-side__wrapper": "cSY",
  "voucher-illustration": "cSh",
  "left-side__wrapper-title": "cSf",
  "voucher-list": "cSK",
  "left-side__wrapper-details": "cSG",
  "right-side": "cSr",
  "multi-accounts": "cSb",
  "right-side__wrapper": "cSy"
};
