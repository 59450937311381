/* import __COLOCATED_TEMPLATE__ from './accountant-access-upgrade.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';

interface AccountReceivableOnboardingAccountantAccessUpgradeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AccountReceivableOnboardingAccountantAccessUpgrade extends Component<AccountReceivableOnboardingAccountantAccessUpgradeSignature> {
  @service declare intl: Services['intl'];
  @service declare flow: Services['flow'];

  button = Button;

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-invite-accountant.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goBack() {
    this.flow.back();
  }
}
