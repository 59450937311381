export default {
  "step-container": "ccf",
  "content": "ccK",
  "sticky-panel": "ccG",
  "banner": "ccr",
  "banner-content": "ccb",
  "banner-text": "ccy",
  "banner-image": "ccU",
  "banner-title": "ccj",
  "banner-description": "ccW body-2",
  "link": "ccu"
};
