export default {
  "details": "UK",
  "disclaimer": "UG",
  "data": "Ur",
  "confirm": "Ub",
  "form": "Uy",
  "textarea": "UU",
  "preview": "Uj",
  "form-title": "UW title1",
  "form-subtitle": "Uu title2",
  "divider": "jS",
  "submit": "jc",
  "doc": "jq",
  "amount-input-error": "jZ"
};
