export default {
  "upsell-cta": "yM",
  "discover-badge": "yP",
  "role-selection-item": "yl",
  "unavailable": "yX",
  "role-headline": "yC",
  "illustration": "yk",
  "role-description": "yJ",
  "description-item": "ys",
  "description-icon": "yF",
  "description-text": "yg",
  "current-role": "yD",
  "icon-outlined": "yV"
};
