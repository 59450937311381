/* import __COLOCATED_TEMPLATE__ from './choose-card-digital.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { ChooseCardDigital } from 'qonto/react/cards/components/flows/choose-card-digital';

interface FlowsCardsChooseVirtualCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseVirtualCard extends Component<FlowsCardsChooseVirtualCardSignature> {
  chooseCardDigital = ChooseCardDigital;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardDigital': typeof FlowsCardsChooseVirtualCard;
  }
}
