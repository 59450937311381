export default {
  "wrapper": "Ww",
  "wrapper-revamp": "WN",
  "container": "Wv",
  "container-revamp": "Wo",
  "box-wrapper": "Wn",
  "warning-label": "Wi",
  "subtitle-container": "WA",
  "info-container": "WY",
  "card": "Wh",
  "disclaimer": "Wf",
  "list": "WK",
  "list-item": "WG body-2",
  "total": "Wr"
};
