export default {
  "step-container": "Kx",
  "content": "Kw",
  "section": "KN",
  "section-content": "Kv",
  "section-content-text": "Ko",
  "section-illustration": "Kn",
  "close-form-cta": "Ki",
  "bank-account-select": "KA"
};
