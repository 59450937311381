export default {
  "row": "Nr",
  "active": "Nb",
  "cell": "Ny",
  "empty": "NU",
  "supplier": "Nj",
  "supplier-content": "NW",
  "declined": "Nu",
  "total-repayment": "vS body-1",
  "amount": "vc",
  "supplier-avatar": "vq mr-16",
  "financing-status": "vZ body-2",
  "installments": "vR body-2",
  "overflow-hidden": "vQ",
  "ellipsis": "ve",
  "completed-on": "vB",
  "financed-amount": "vE body-2"
};
