export default {
  "container": "NV",
  "header-wrapper": "NL mb-16",
  "header": "NT",
  "logo": "Nz mr-16",
  "title": "Na title-4",
  "description": "NH body-2",
  "conditions": "NO",
  "label": "Nm caption mb-4",
  "tags": "Np"
};
