export default {
  "container": "YN",
  "disabled-option": "Yv",
  "content": "Yo",
  "spinner": "Yn",
  "subtitle": "Yi",
  "details-table": "YA",
  "caption": "YY",
  "tooltip-icon": "Yh",
  "tooltip-icon-right": "Yf",
  "card-monthly-cost": "YK",
  "card-monthly-discounted-cost": "YG",
  "strikethrough": "Yr",
  "purple-text": "Yb",
  "budget-label": "Yy",
  "card-cost-label": "YU",
  "card-billing-account": "Yj"
};
