export default {
  "container": "YZ",
  "centered": "YR",
  "content": "YQ",
  "spinner": "Ye",
  "holder-selector-wrapper": "YB mb-16",
  "holder-selector-error": "YE",
  "info-text": "Yd",
  "error-text": "YI",
  "lottie-illustration": "Yt",
  "info-text-1": "YM",
  "info-text-2": "YP"
};
