export default {
  "step-container": "ya",
  "subtitle": "yH",
  "step-container-content": "yO",
  "continue-button": "ym",
  "period-select": "yp",
  "period-dropdown": "yx",
  "readonly": "yw",
  "period-error": "yN",
  "toggle-options-container": "yv",
  "receipt-code-toggle-input": "yo"
};
