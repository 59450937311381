export default {
  "container": "ni",
  "container-content": "nA",
  "content": "nY",
  "underline": "nh",
  "inline-content": "nf",
  "payment-rate": "nK",
  "payment-subtitle": "nG",
  "grey-text": "nr",
  "earnings-header": "nb",
  "earnings-tooltip": "ny"
};
