export default {
  "container": "cZn",
  "container--collapsed": "cZi",
  "spin": "cZA",
  "cta-tooltip": "cZY",
  "cta--collapsed": "cZh",
  "cta--expanded": "cZf",
  "collapsed-svg-icon": "cZK",
  "expanded-svg-icon": "cZG",
  "generate-logo-cta": "cZr",
  "badge": "cZb",
  "scaleDown": "cZy",
  "fadeIn": "cZU",
  "textRiseButton": "cZj",
  "cta-description": "cZW",
  "textRiseDescription": "cZu"
};
