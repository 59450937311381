export default {
  "invite-review": "bu",
  "invite-review-info": "yS",
  "invite-review-info--invitee": "yc",
  "disclaimer-button": "yq",
  "info-block": "yZ",
  "ev": "yR",
  "tooltip": "yQ",
  "description": "ye"
};
