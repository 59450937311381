export default {
  "container": "Gx",
  "content": "Gw",
  "buttons": "GN",
  "genders": "Gv",
  "birth-details": "Go",
  "has-error": "Gn",
  "gender-option": "Gi",
  "radio-button": "GA"
};
