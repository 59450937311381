/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { EARLY_REPAYMENT_ERRORS } from 'qonto/constants/financing';
import { EarlyRepaymentOption } from 'qonto/react/api/models/repayment-option';
import { Overview } from 'qonto/react/financing/components/flows/pay-later/early-repayment/overview';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsFinancingPayLaterEarlyRepaymentOverviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterEarlyRepaymentOverviewComponent extends Component<FlowsFinancingPayLaterEarlyRepaymentOverviewSignature> {
  Overview = Overview;

  @service declare financing: Services['financing'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  @tracked error: string | null = null;

  get bankAccount() {
    let { mainAccount } = this.organizationManager.organization;

    return {
      name: mainAccount.name,
      balance: {
        value: mainAccount.authorizedBalance,
        currency: mainAccount.balanceCurrency,
      },
    };
  }

  get repaymentOption() {
    // @ts-expect-error
    let { repaymentOptions, currentRepaymentOption } = this.args.context;
    // @ts-expect-error
    return repaymentOptions.find(option => option.earlyRepaymentOption === currentRepaymentOption);
  }

  // @ts-expect-error
  trackPostEarlyRepayment(status) {
    // @ts-expect-error
    let { currentRepaymentOption } = this.args.context;
    this.segment.track('pay-later_early-repayment-submitted', {
      repayment_type:
        currentRepaymentOption === EarlyRepaymentOption.Full ? 'repay_fully' : 'next_installment',
      status,
    });
  }

  @action
  postEarlyRepayment() {
    this.postEarlyRepaymentTask.perform().catch(ignoreCancelation);
  }

  @action
  openTopUpFlow() {
    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-topup',
      stepId: 'amount',
    });
  }

  postEarlyRepaymentTask = dropTask(async () => {
    // @ts-expect-error
    let { financingId, currentRepaymentOption } = this.args.context;

    try {
      await this.financing.postEarlyRepayment(financingId, currentRepaymentOption);
      this.trackPostEarlyRepayment('success');
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      this.trackPostEarlyRepayment('fail');
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 422) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      this.error = error.errors?.[0]?.code;

      if (!this.error || !(this.error in EARLY_REPAYMENT_ERRORS)) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::EarlyRepayment::Overview': typeof FlowsFinancingPayLaterEarlyRepaymentOverviewComponent;
  }
}
