/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, race, rawTimeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  ONBOARDING_OCR_FAILED_EVENT,
  ONBOARDING_OCR_SUCCEDED_EVENT,
  ONBOARDING_SOURCE,
  ONBOARDING_STATUS,
  ONBOARDING_TRACKING_EVENTS,
  ONBOARDING_WEBSOCKET_TIMEOUT_MS,
} from 'qonto/constants/receivable-invoice';
import { OcrLoading } from 'qonto/react/components/account-receivable/summary/ocr-loading/ocr-loading';
import { StepSelector } from 'qonto/react/components/account-receivable/summary/step-selector/step-selector';
import { getNextInvoiceNumberPreview } from 'qonto/utils/invoices/preview';

interface FlowsAccountReceivableOnboardingSummarySignature {
  // The arguments accepted by the component
  Args: {
    pushFlow: (flowName: string, stepId: string) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

/**
 * @typedef {import("../../../../react/components/account-receivable/summary/step-selector/step-selector").StepSelectorProps} Step
 */

export default class FlowsAccountReceivableOnboardingSummary extends Component<FlowsAccountReceivableOnboardingSummarySignature> {
  stepSelector = StepSelector;
  ocrLoading = OcrLoading;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare router: Services['router'];
  @service declare modals: Services['modals'];
  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];
  @service
  declare accountReceivableOnboardingUploadManager: Services['accountReceivableOnboardingUploadManager'];
  @service declare mollie: Services['mollie'];

  @tracked isWaitingForOCR = false;
  @tracked hasOCRTimedOut = false;

  @tracked displayStepValidation = false;

  constructor(owner: unknown, args: FlowsAccountReceivableOnboardingSummarySignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.shouldToastOnInvalidFile = true;
    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.registerCallback({
      onUploadFinished: () => this.onFileUploadFinishTask.perform(),
      onUploadStarted: this.onUploadStarted,
    });
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.resetState();
  }

  @action
  // @ts-expect-error
  goTo(stepId) {
    // @ts-expect-error
    this.args.context.nextStepId = stepId;
    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  onUploadStarted() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.IMPORT_STARTED);
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  get organization() {
    return this.onboardingState.organization;
  }

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  get customizationStepStatus() {
    return this.onboardingState?.stepStatusCustomization;
  }

  get nextInvoiceNumberPreview() {
    return getNextInvoiceNumberPreview(this.settings);
  }

  get hideAccountantAccessStep() {
    return (
      !this.canAccessAccountantAccess &&
      (!variation('feature--freemium-to-paid') ||
        (this.organization.onboardingPartner === 'regate' &&
          this.upsellManager.shouldShowFreemiumUpgrade))
    );
  }

  get hidePaymentLinksStep() {
    return this.abilities.cannot('write paymentLink');
  }

  get paymentLinksStepStatus() {
    return this.mollie.hasOnboarded ? 'confirmed' : 'empty';
  }

  get paymentLinksStepSubtitle() {
    return this.paymentLinksStepStatus === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.payment-links.subtitle.completed')
      : this.intl.t('receivable-invoices.onboarding.steps.payment-links.subtitle.empty');
  }

  /**
   * TODO: Replace with conditional based translated content, actual statuses and actions
   *
   * @returns {Array<Step>}
   */
  get steps() {
    // Extract context arguments
    // @ts-expect-error
    const { source, onboardingState, isItalianOrganization } = this.args.context;
    const isQontoInvoicing = this.subscriptionManager.isQontoInvoicing;
    const stepsDisabled = this.isWaitingForOCR && !this.hasOCRTimedOut;

    // Define customization step (used in two different positions depending on isQontoInvoicing)
    const customizationStep = {
      title: this.intl.t('receivable-invoices.onboarding.steps.customization.title'),
      subtitle: this.customizationStepSubtitle,
      status: this.customizationStepStatus,
      icon: 'pole',
      onClick: () => {
        this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
          source,
          step: 'customization',
          status: this.customizationStepStatus,
        });
        this.goTo('customization-logo');
      },
      dataTestSelector: 'customization-step',
      disabled: stepsDisabled,
    };

    // Define numbering step
    const numberingStep = {
      title: this.intl.t('receivable-invoices.onboarding.steps.numbering.title'),
      status: onboardingState.stepStatusNumbering,
      subtitle: this.numberingStepSubtitle,
      icon: 'invoice',
      onClick: () => {
        this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
          source,
          step: 'numbering',
          status: onboardingState.stepStatusNumbering,
        });
        this.goTo('numbering');
      },
      dataTestSelector: 'numbering-step',
      disabled: stepsDisabled,
    };

    // Define company details step
    const companyDetailsStep = {
      title: this.intl.t('receivable-invoices.onboarding.steps.company-details.title.company'),
      status: onboardingState.stepStatusCompanyDetails,
      subtitle: this.companyDetailsStepSubtitle,
      icon: 'company',
      onClick: () => {
        this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
          source,
          step: 'company-details',
          status: onboardingState.stepStatusCompanyDetails,
        });
        this.goTo(isItalianOrganization ? 'it-company-details' : 'company-details');
      },
      dataTestSelector: 'company-details-step',
      disabled: stepsDisabled,
    };

    // Define accountant access step (conditionally included)
    const accountantAccessStep = !this.hideAccountantAccessStep && {
      title: this.intl.t('receivable-invoices.onboarding.steps.accountant-access.title'),
      status: this.accountantAccessStepStatus,
      subtitle: this.accountantAccessStepSubtitle,
      icon: 'calculator',
      onClick: () => {
        this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_STARTED, {
          source,
          step: 'accountant',
          status: this.accountantAccessStepStatus,
        });

        if (this.canAccessAccountantAccess) {
          this.args.pushFlow('member-invite', 'personal-info');
        } else if (this.upsellManager.shouldShowFreemiumUpgrade) {
          this.goTo('accountant-access-upgrade');
        }
      },
      dataTestSelector: 'accountant-access-step',
      disabled: stepsDisabled,
    };

    // Define payment links step (conditionally included)
    const paymentLinksStep = !this.hidePaymentLinksStep && {
      title: this.intl.t('receivable-invoices.onboarding.steps.payment-links.title'),
      status: this.paymentLinksStepStatus,
      subtitle: this.paymentLinksStepSubtitle,
      icon: 'payment-link',
      onClick: () => {
        this.segment.track('payment-link_activation_started', {
          origin: 'ar_onboarding',
        });
        this.args.pushFlow('payment-links-onboarding', 'intro');
      },
      dataTestSelector: 'payment-links-step',
      disabled: this.paymentLinksStepStatus === 'confirmed',
    };

    // Build the steps array with conditional elements
    // The order and inclusion of steps depends on various conditions
    const stepsArray = [
      // For Qonto Invoicing, customization step comes first
      isQontoInvoicing && customizationStep,
      numberingStep,
      companyDetailsStep,
      // For non-Qonto Invoicing, customization step comes after company details
      !isQontoInvoicing && customizationStep,
      accountantAccessStep,
      paymentLinksStep,
    ];

    // Filter out any falsy values (steps that shouldn't be included)
    return stepsArray.filter(Boolean);
  }

  get displayFileDropzone() {
    return (
      !this.onboardingState?.prefilledAt &&
      !this.isWaitingForOCR &&
      !this.onboardingState?.hasManuallyCompletedSteps
    );
  }

  get isEligibleForFreeTrial() {
    return Boolean(this.subscriptionManager.currentSubscription?.arAddonTrial);
  }

  get canAccessAccountantAccess() {
    return this.abilities.can('access accountant-access');
  }

  get accountantAccessStepStatus() {
    if (!this.canAccessAccountantAccess) {
      return this.isEligibleForFreeTrial ? 'tryForFree' : 'upgrade';
    }

    return this.onboardingState.stepStatusAccountantAccess;
  }

  get numberingStepSubtitle() {
    // @ts-expect-error
    return this.args.context.onboardingState.stepStatusNumbering === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.numbering.subtitle.completed', {
          firstInvoiceNumber: this.nextInvoiceNumberPreview,
          htmlSafe: true,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.numbering.subtitle.empty');
  }

  get companyDetailsStepSubtitle() {
    // @ts-expect-error
    let { legalCountry } = this.args.context;
    // @ts-expect-error
    return this.args.context.onboardingState.stepStatusCompanyDetails === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.company-details.subtitle.completed', {
          legalCountry,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.company-details.subtitle.empty');
  }

  get customizationStepSubtitle() {
    // @ts-expect-error
    let { legalCountry } = this.args.context;
    return this.customizationStepStatus === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.customization.subtitle.completed', {
          legalCountry,
        })
      : this.intl.t('receivable-invoices.onboarding.steps.customization.subtitle.empty', {
          legalCountry,
        });
  }

  get accountantAccessStepSubtitle() {
    if (!this.canAccessAccountantAccess) {
      return this.isEligibleForFreeTrial
        ? this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.free-trial')
        : this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.upgrade');
    }

    return this.onboardingState.stepStatusAccountantAccess === 'confirmed'
      ? this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.completed')
      : this.intl.t('receivable-invoices.onboarding.steps.accountant-access.subtitle.empty');
  }

  get exampleInvoice() {
    return {
      organization: {
        locale: this.organization.locale,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        shortLegalForm: this.organization.shortLegalForm,
        legalCountry: this.organization.legalCountry,
        address: this.organization.address,
        contactEmail: this.organization.contactEmail,
        vatNumber: this.organization.vatNumber,
        taxNumber: this.organization.taxNumber,
      },
      bic: this.organization.mainAccount?.bic,
      iban: this.organization.mainAccount?.iban,
      beneficiaryName: this.organization.legalName,
    };
  }

  get reuploadCta() {
    if (this.onboardingState.hasManuallyCompletedSteps) {
      return this.intl.t('receivable-invoices.onboarding.invoice-upload.footer.upload.text');
    }

    return this.intl.t('receivable-invoices.onboarding.invoice-upload.footer.reupload.text');
  }

  get logo() {
    return this.organization.isDefaultAvatar === false ? this.organization.picture : null;
  }

  onFinishTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE);
    this.displayStepValidation = true;

    if (!this.onboardingState.areMandatoryStepsCompleted) {
      return;
    }

    try {
      this.onboardingState.status = ONBOARDING_STATUS.COMPLETED;
      await this.onboardingState.save();
      // @ts-expect-error
      this.args.context.nextStepId = 'success-page';
      // @ts-expect-error
      this.args.transitionToNext();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  onSkipTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.SKIP);
    try {
      let shouldContinueSkipping = true;

      if (!this.onboardingState.areMandatoryStepsCompleted) {
        let result = await this.openSkipModalTask.perform();
        // @ts-expect-error
        shouldContinueSkipping = result === 'confirm';
        if (shouldContinueSkipping) {
          this.segment.track(ONBOARDING_TRACKING_EVENTS.SKIP_CONFIRMED);
        }
      }
      if (shouldContinueSkipping) {
        this.onboardingState.status = ONBOARDING_STATUS.SKIPPED;
        await this.onboardingState.save();
        this.router.transitionTo('receivable-invoices.new');
      }
      return shouldContinueSkipping;
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  openSkipModalTask = dropTask(async () => {
    let link =
      // @ts-expect-error
      this.args.context.source === ONBOARDING_SOURCE.RECURRING_INVOICES
        ? this.intl.t('section-title.recurring-invoices')
        : this.intl.t('section-title.client-invoices');

    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('receivable-invoices.onboarding.summary.skip-modal.title'),
      description: this.intl.t('receivable-invoices.onboarding.summary.skip-modal.description', {
        link,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.onboarding.ctas.skip'),
    });
  });

  onFileUploadFinishTask = dropTask(async () => {
    this.isWaitingForOCR = true;

    // @ts-expect-error
    let ocrSuccessEvent = this.notifierManager.waitForEventTask.perform(
      ONBOARDING_OCR_SUCCEDED_EVENT
    );
    // @ts-expect-error
    let ocrFailureEvent = this.notifierManager.waitForEventTask.perform(
      ONBOARDING_OCR_FAILED_EVENT
    );

    let websocket = await race([
      ocrFailureEvent,
      ocrSuccessEvent,
      rawTimeout(ONBOARDING_WEBSOCKET_TIMEOUT_MS),
    ]);

    switch (websocket?.event) {
      case ONBOARDING_OCR_SUCCEDED_EVENT:
        this.isWaitingForOCR = false;
        this.store.pushPayload('accounts-receivable-onboarding', {
          data: {
            id: websocket.payload.object_id,
            type: 'accounts_receivable_onboarding',
            attributes: websocket.payload.object,
          },
        });
        break;
      case ONBOARDING_OCR_FAILED_EVENT:
        this.isWaitingForOCR = false;
        this.toastFlashMessages.toastInfo(
          this.intl.t('receivable-invoices.onboarding.toasts.info.ocr-failed')
        );
        break;
      default:
        this.hasOCRTimedOut = true;
        break;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::Summary': typeof FlowsAccountReceivableOnboardingSummary;
  }
}
