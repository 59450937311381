export default {
  "checks": "hy",
  "content": "hU",
  "form": "hj",
  "title": "hW",
  "description": "hu",
  "wrapper": "fS",
  "aside": "fc",
  "amount-field": "fq"
};
