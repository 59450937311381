/* import __COLOCATED_TEMPLATE__ from './upgrade-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Button, LottiePlayer, type LottiePlayerProps } from '@repo/design-system-kit';

interface UpgradeModalSignature {
  // The arguments accepted by the component
  Args: {
    close: () => void;
    feature?: string;
    trackProps?: { [key: string]: string };
    lottieProps: LottiePlayerProps;
    skipToResumeScreen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UpgradeModalComponent extends Component<UpgradeModalSignature> {
  BADGE_TYPE = BADGE_TYPE;

  lottiePlayer = LottiePlayer;
  button = Button;
  badge = Badge;

  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare userManager: Services['userManager'];
  @service declare segment: Services['segment'];

  @tracked isLoading = true;
  @tracked showResumeFlag = false;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.init();
  }

  async init() {
    await this.upsellManager.getSelectedBillerPricePlan();
    this.showResumeFlag = this.args.skipToResumeScreen || false;
    this.isLoading = false;
  }

  get billerPricePlanName() {
    return this.upsellManager.billerPricePlan?.brand_name || '';
  }

  get shouldShowResumeFreemiumUpgrade() {
    return this.upsellManager.shouldResumeFreemiumUpgrade && this.showResumeFlag;
  }

  get lottieProps(): LottiePlayerProps {
    return {
      autoplay: true,
      loop: false,
      ...this.args.lottieProps, // Override defaults with passed values
    };
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get userFirstName() {
    // @ts-expect-error
    return this.userManager.currentUser?.firstName || '';
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial(this.args.feature || '') || this.hasInitialTrial
    );
  }

  @action
  upsell() {
    if (this.upsellManager.shouldResumeFreemiumUpgrade && !this.showResumeFlag) {
      this.showResumeFlag = true;
      return;
    }

    this.upsellManager.upsell({ feature: this.args.feature });
    this.segment.track('qonto_invoicing_upsell_trigger_clicked', {
      feature: this.args.feature,
      ...this.args.trackProps,
    });
  }

  @action
  resumeUpsell() {
    this.upsellManager.upsell({ feature: this.args.feature });
    this.segment.track('qonto-business-onboarding_resume-screen_continue-upsell_clicked', {
      'sub-product': 'qonto_invoicing',
      ...this.args.trackProps,
    });
  }

  @action
  restartUpsell() {
    this.upsellManager.upsell({ feature: this.args.feature, restart: true });
    this.segment.track('qonto-business-onboarding_resume-screen_change-plan_clicked', {
      'sub-product': 'qonto_invoicing',
      ...this.args.trackProps,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpgradeModal: typeof UpgradeModalComponent;
  }
}
