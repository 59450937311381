export default {
  "guests-loading": "cRb",
  "header": "cRy",
  "body": "cRU",
  "members": "cRj",
  "title": "cRW caption-bold",
  "placeholder-container": "cRu",
  "icon": "cQS",
  "details": "cQc"
};
